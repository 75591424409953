define('politiek/controllers/parties/edit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            updateParty: function updateParty() {

                // console.log(this.model.get("leader"));
                // console.log(this.get("name"));
                //
                // var person = this.store.createRecord('person', {
                //     name: this.get("name"),
                // });
                // this.model.set('leader', person);
                // person.save();

                this.model.save();
            }
        }
    });
});