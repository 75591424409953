define('politiek/controllers/cabinets/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            publish: function publish() {
                var newCabinet = this.store.createRecord('cabinet', {
                    name: this.get('name'),
                    link: this.get('link'),
                    startDate: new Date(this.get('startdate')),
                    endDate: new Date(this.get('enddate'))
                });

                var partyIds = _ember['default'].$('#parties').val();
                // var parties = [];
                for (var i = 0; i < partyIds.length; i++) {
                    var partyId = partyIds[i];
                    var party = this.store.peekRecord('party', partyId);
                    newCabinet.get('parties').pushObject(party);
                    party.save();
                    // parties.push(party);
                }
                // console.log(parties);
                // newParty.set("parties", parties);

                newCabinet.save();
            }
        }
    });
});