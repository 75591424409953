define('politiek/controllers/persons', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            updatePerson: function updatePerson(person) {
                person.save();
            },
            newPerson: function newPerson() {
                var newPerson = this.store.createRecord('person', {
                    name: this.get("newPerson")
                });
                newPerson.save();
                this.set("newPerson", "");
            }
        }
    });
});