define('politiek/models/cabinet', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
    exports['default'] = _emberData['default'].Model.extend({
        name: _emberData['default'].attr('string'),
        link: _emberData['default'].attr('string'),
        startDate: _emberData['default'].attr('date'),
        endDate: _emberData['default'].attr('date'),
        parties: _emberData['default'].hasMany('party', { inverse: 'cabinets' }),

        period: _ember['default'].computed('startDate', 'endDate', function () {
            var startDate = new Date(this.get('startDate'));
            if (this.get('endDate') === null) {
                return startDate.getFullYear() + "-...";
            } else {
                var endDate = new Date(this.get('endDate'));
                return startDate.getFullYear() + "-" + endDate.getFullYear();
            }
        })
    });
});