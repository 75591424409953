define('politiek/components/party-select', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        store: _ember['default'].inject.service(),

        actions: {
            show: function show() {
                var _this = this;

                var store = this.get('store');

                store.findAll('party').then(function (parties) {
                    _this.set('show', true);
                    _this.set("parties", parties);
                });
            },
            save: function save() {
                var _this2 = this;

                var store = this.get('store');

                console.log(this.get('newPartyId'));
                var party = store.peekRecord('party', this.get('newPartyId'));
                var person = this.get('person');
                person.set('party', party);
                person.save().then(function () {
                    return party.save();
                }).then(function () {
                    _this2.set("show", false);
                    _this2.set("parties", []);
                });
            },
            updateValue: function updateValue() {
                this.set('newPartyId', event.target.value);
            }
        }

    });
});