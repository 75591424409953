define('politiek/models/party', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
    exports['default'] = _emberData['default'].Model.extend({
        name: _emberData['default'].attr('string'),
        fullName: _emberData['default'].attr('string'),
        abbreviation: _emberData['default'].attr('string'),
        color: _emberData['default'].attr('string'),
        website: _emberData['default'].attr('string'),
        programLink: _emberData['default'].attr('string'),
        programName: _emberData['default'].attr('string'),
        facebook: _emberData['default'].attr('string'),
        twitter: _emberData['default'].attr('string'),
        youtube: _emberData['default'].attr('string'),
        instagram: _emberData['default'].attr('string'),
        seats2012: _emberData['default'].attr('number'),
        seats2010: _emberData['default'].attr('number'),
        seatsEK2015: _emberData['default'].attr('number'),
        seatsEK2011: _emberData['default'].attr('number'),
        foundingYear: _emberData['default'].attr('number'),
        newsItems: _emberData['default'].hasMany('newsItem'),
        leader: _emberData['default'].belongsTo('person'),
        persons: _emberData['default'].hasMany('person', { inverse: 'party' }),
        cabinets: _emberData['default'].hasMany('cabinet', { inverse: 'parties' }),

        websiteTitle: _ember['default'].computed('website', function () {
            return this.get('website') != null ? this.get('website').replace(/https?:\/\/(www.)?/g, "") : "";
        }),
        cabinetIds: _ember['default'].computed('cabinets', function () {
            var cabinets = this.hasMany('cabinets').ids();
            return cabinets.join(" ");
        })
    });
});