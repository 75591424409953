define("politiek/templates/components/tweedekamer-diagram", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 4,
              "column": 4
            }
          },
          "moduleName": "politiek/templates/components/tweedekamer-diagram.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createAttrMorph(element0, 'data-party');
          morphs[2] = dom.createAttrMorph(element0, 'data-x');
          morphs[3] = dom.createAttrMorph(element0, 'data-y');
          morphs[4] = dom.createAttrMorph(element0, 'data-id');
          morphs[5] = dom.createAttrMorph(element0, 'style');
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["seat ", ["get", "seat.party.name", ["loc", [null, [3, 23], [3, 38]]]]]]], ["attribute", "data-party", ["concat", [["get", "seat.party.name", ["loc", [null, [3, 56], [3, 71]]]]]]], ["attribute", "data-x", ["concat", [["get", "seat.x", ["loc", [null, [3, 85], [3, 91]]]]]]], ["attribute", "data-y", ["concat", [["get", "seat.y", ["loc", [null, [3, 105], [3, 111]]]]]]], ["attribute", "data-id", ["concat", [["get", "seat.id", ["loc", [null, [3, 126], [3, 133]]]]]]], ["attribute", "style", ["concat", ["background-color:", ["get", "seat.party.color", ["loc", [null, [3, 163], [3, 179]]]]]]]],
        locals: ["seat"],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 6
          }
        },
        "moduleName": "politiek/templates/components/tweedekamer-diagram.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "tweedekamer");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "seats", ["loc", [null, [2, 12], [2, 17]]]]], [], 0, null, ["loc", [null, [2, 4], [4, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});