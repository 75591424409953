define('politiek/controllers/parties/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            publishParty: function publishParty() {
                var newParty = this.store.createRecord('party', {
                    name: this.get('name'),
                    color: this.get('color'),
                    timestamp: new Date().getTime()
                });
                newParty.save();
            }
        }
    });
});