define('politiek/models/news-item', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
    exports['default'] = _emberData['default'].Model.extend({
        party: _emberData['default'].belongsTo('party'),
        title: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        provider: _emberData['default'].attr('string'),
        url: _emberData['default'].attr('string'),
        date: _emberData['default'].attr('date'),

        shortDescription: _ember['default'].computed('description', function () {
            return (this.get('description') + "").substr(0, 100) + "...";
        }),
        formattedDate: _ember['default'].computed('date', function () {
            var date = new Date(this.get('date'));
            var months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
            return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
            // + " " + ("0"+date.getHours()).slice(-2) + ":" + ("0"+date.getMinutes()).slice(-2);
        })

    });
});