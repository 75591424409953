define('politiek/controllers/parties', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        sortProperties: ['timestamp'],
        sortAscending: false, // sorts post by timestamp

        actions: {
            updateParty: function updateParty(party) {
                party.save();
            }
        }
    });
});