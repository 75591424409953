define('politiek/routes/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return this.store.findAll('party');
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            this.store.findAll('seat').then(function (tags) {
                controller.set('seats', tags);
            });
            this.store.findAll('cabinet').then(function (cabinets) {
                controller.set('cabinets', cabinets);
            });
        }
    });
});