define('politiek/controllers/index', ['exports', 'ember', 'npm:mixitup'], function (exports, _ember, _npmMixitup) {
    var mixer;

    exports['default'] = _ember['default'].Controller.extend({
        init: function init() {
            this._super();
            _ember['default'].run.schedule("afterRender", this, function () {
                this.send("loaded", this.store);
            });
        },

        actions: {
            loaded: function loaded(store) {
                mixer = (0, _npmMixitup['default'])(_ember['default'].$('#wrapper'));
            },
            showCabinet: function showCabinet(id) {
                console.log(mixer.getState());
                if (mixer.getState().totalHide > 0 && mixer.getState().activeFilter.selector === "." + id) {
                    mixer.show();
                } else {
                    mixer.filter("." + id);
                }
                // let element = Ember.$(event.target);
                // if(element.hasClass("active")){
                //     Ember.$(".party").removeClass("inactive");
                // } else {
                //     Ember.$(".party").removeClass("inactive").addClass("inactive");
                //     Ember.$(".party." + id).removeClass("inactive active").addClass("active");
                // }
                // element.toggleClass("active");
            },
            newsItemClick: function newsItemClick(item) {
                window.open(item.get("url"));
            }
        }
    });
});