define('politiek/components/person-select', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        store: _ember['default'].inject.service(),
        newPartyid: null,

        actions: {
            show: function show() {
                var _this = this;

                var store = this.get('store');

                this.get('party').get("persons").then(function (persons) {
                    _this.set('show', true);
                    _this.set("persons", persons);
                });
            },
            save: function save() {
                var _this2 = this;

                var store = this.get('store');
                var person = store.peekRecord('person', this.get('newPersonId'));
                var party = this.get('party');
                party.set(this.get('select'), person);
                party.save().then(function () {
                    return person.save();
                }).then(function () {
                    _this2.set("show", false);
                    _this2.set("persons", []);
                });
            },
            updateValue: function updateValue() {
                this.set('newPersonId', event.target.value);
            }
        }

    });
});