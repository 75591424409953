define('politiek/router', ['exports', 'ember', 'politiek/config/environment'], function (exports, _ember, _politiekConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _politiekConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('parties', function () {
      this.route('new');
      this.route('edit', { path: "/:party_id" });
    });

    this.route('cabinets', function () {
      this.route('new');
    });
    this.route('persons');
  });

  exports['default'] = Router;
});