define('politiek/app', ['exports', 'ember', 'politiek/resolver', 'ember-load-initializers', 'politiek/config/environment'], function (exports, _ember, _politiekResolver, _emberLoadInitializers, _politiekConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _politiekConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _politiekConfigEnvironment['default'].podModulePrefix,
    Resolver: _politiekResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _politiekConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});